import { FieldValues, Resolver } from "react-hook-form";
import * as Yup from "yup";

export const yupResolver =
  <TFieldValues extends FieldValues>(
    schema: Yup.ObjectSchema<any>
  ): Resolver<TFieldValues> =>
  async (values) => {
    try {
      const data = await schema.validate(values, {
        abortEarly: false,
      });

      return {
        values: data,
        errors: {},
      };
    } catch (yupError: any) {
      const errors = yupError.inner.reduce(
        (allErrors: any, currentError: any) => {
          return {
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? "validation",
              message: currentError.message,
            },
          };
        },
        {}
      );

      return {
        values: {},
        errors,
      };
    }
  };
