import styled from "styled-components";
import Bridges from "../../assets/images/bridges.webp";
import { COLORS, FONT_WEIGHT } from "../../theme";

export const Hero = styled.div`
  width: 100%;
  height: 80vh;
  background-image: url(${Bridges});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  color: ${COLORS.WHITE};

  @media (max-width: 1023px) {
    height: auto;
    margin-top: 78px;
  }

  @media (max-width: 700px) {
    margin-top: 56px;
  }
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.hero {
    @media (max-width: 1023px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 48px 0;
    }
  }
`;

export const HeroInfo = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const HeroTitle = styled.p`
  color: ${COLORS.WHITE};
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 56px;

  @media (max-width: 1420px) {
    font-size: 48px;
  }

  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 48px;
  }
`;

export const Option = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 24px;
  }
`;

export const Options = styled.div`
  text-align: right;
  margin: 32px 0;
  &:last-of-type,
  :first-of-type {
    margin: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
  }

  p {
    font-size: 28px;
    font-weight: ${FONT_WEIGHT.BOLD};
    padding-bottom: 8px;

    @media (max-width: 1420px) {
      font-size: 24px;
    }
  }

  a {
    color: ${COLORS.WHITE};
    text-decoration: none;
    font-size: 18px;
    padding: 4px 0 4px 8px;
  }

  .hr {
    background-color: ${COLORS.WHITE};
    height: 1px;
    width: 240px;
    margin: 8px 0 0 auto;

    @media (max-width: 1420px) {
      width: 180px;
    }

    @media (max-width: 1023px) {
      margin: 8px auto 0 auto;
    }
  }
`;

export const ButtonBox = styled.div`
  margin-top: 40px;

  @media (max-width: 1023px) {
    margin-top: 18px;
  }
`;
