import { useQuery } from "@tanstack/react-query";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { getExchange } from "../services/extraData";
import Spinner from "../components/Spinner";

type ExtraDataContextProps = {
  exchange?: {
    dolar: number;
    euro: number;
  };
  stock?: {
    b3: number;
    euronextLisboa: number;
  };
};

export const ExtraDataContext = createContext<ExtraDataContextProps>({
  exchange: {
    dolar: Number(sessionStorage.getItem("dolarExchange")) ?? null,
    euro: Number(sessionStorage.getItem("euroExchange")) ?? null,
  },
  stock: {
    b3: Number(sessionStorage.getItem("b3Exchange")) ?? null,
    euronextLisboa:
      Number(sessionStorage.getItem("euroNextLisboaExchange")) ?? null,
  },
});

type ExtraDataProviderProps = {
  children: ReactNode;
};

export const ExtraDataProvider = ({ children }: ExtraDataProviderProps) => {
  const [exchange, setExchange] = useState({
    dolar: Number(sessionStorage.getItem("dolarExchange")) ?? null,
    euro: Number(sessionStorage.getItem("euroExchange")) ?? null,
  });

  const [stock, setStock] = useState({
    b3: Number(sessionStorage.getItem("b3Exchange")) ?? null,
    euronextLisboa:
      Number(sessionStorage.getItem("euroNextLisboaExchange")) ?? null,
  });

  const { isFetching } = useQuery(["getExchanges"], getExchange, {
    onSettled: (response) => {
      if (response?.exchanges) {
        const dolar = 1 / response.exchanges.USD;
        const euro = 1 / response.exchanges.EUR;

        setExchange({
          dolar: dolar,
          euro: euro,
        });

        sessionStorage.setItem("dolarExchange", dolar?.toString());
        sessionStorage.setItem("euroExchange", euro?.toString());
      }

      if (response?.stock) {
        setStock({
          b3: response.stock.b3,
          euronextLisboa: response.stock.euronextLisboa,
        });

        sessionStorage.setItem("b3Exchange", response?.stock?.b3?.toString());
        sessionStorage.setItem(
          "euroNextLisboaExchange",
          response?.stock?.euronextLisboa?.toString()
        );
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !exchange?.dolar || !exchange?.euro,
  });

  const value = useMemo(
    () => ({
      exchange,
      stock,
    }),
    [exchange, stock]
  );

  return (
    <ExtraDataContext.Provider value={value}>
      <>
        {isFetching && <Spinner />}
        {children}
      </>
    </ExtraDataContext.Provider>
  );
};

export const useExtraData = () => {
  return useContext(ExtraDataContext);
};
