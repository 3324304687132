import styled from "styled-components";

export const Box = styled.div`
  width: 1400px;
  margin: 0 auto;

  @media (max-width: 1420px) {
    width: 1200px;
  }

  @media (max-width: 1200px) {
    width: 90%;
  }
`;
