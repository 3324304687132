import { ButtonProps, Content, Spinner } from "./style";

export default function Button({
  width,
  title,
  secondary = false,
  isLoading = false,
  ...props
}: ButtonProps) {
  return (
    <Content
      width={width}
      secondary={secondary}
      disabled={isLoading}
      {...props}
    >
      {isLoading && <Spinner />}
      {title ?? "Saiba mais >"}
    </Content>
  );
}
