import Title from "../Title";
import { ContentInfo, ContentTop, ContentTopBox, PageProps } from "./style";

export default function ContentPage({
  backgroundImage,
  title,
  text,
  children,
  cover = false,
}: PageProps) {
  return (
    <>
      <ContentTop backgroundImage={backgroundImage} cover={cover}>
        <ContentTopBox title={title}>
          <div>
            {title ? <Title text={title ?? ""} /> : ""}
            {text ? <h1>{text}</h1> : null}
          </div>
        </ContentTopBox>
      </ContentTop>
      <ContentInfo>{children}</ContentInfo>
    </>
  );
}
