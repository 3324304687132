import * as Yup from "yup";

export const typeSchema = Yup.object().shape({
  name: Yup.string()
    .required("Nome completo é obrigatório")
    .min(1, "Nome completo é obrigatório")
    .max(50, "Nome completo não pode exceder 50 caracteres"),

  email: Yup.string()
    .required("E-mail é obrigatório")
    .min(1, "E-mail é obrigatório")
    .max(50, "E-mail não pode exceder 50 caracteres"),

  phone: Yup.string()
    .required("Telefone é obrigatório")
    .min(1, "Telefone é obrigatório"),

  message: Yup.string()
    .optional()
    .max(200, "Mensagem não pode exceder 200 caracteres"),
});

export type TypeForm = Yup.InferType<typeof typeSchema>;
