import styled from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";
import { ReactNode } from "react";

export type PageProps = {
  backgroundImage?: string;
  title?: string;
  text?: string;
  children?: ReactNode;
  cover?: boolean;
};

export const ContentTop = styled.div<PageProps>`
  background: ${({ backgroundImage }) =>
    `linear-gradient(to left, white 20%, transparent 20%), url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-position: left;
  background-size: ${({ cover }) => (!cover ? "contain" : "cover")};
  padding: 120px 0;

  @media (max-width: 1200px) {
    background-size: cover;
    background-position: top;
  }

  @media (max-width: 1023px) {
    margin: 0 auto;
    background: ${({ backgroundImage }) =>
      `linear-gradient(white 15%, transparent 15%), url(${backgroundImage})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 0 0 180px 0;
  }

  @media (max-width: 600px) {
    padding-bottom: 120px;
  }
`;

export const ContentTopBox = styled.div<PageProps>`
  width: 50%;
  background-color: #fff;
  margin-left: auto;
  box-shadow: 4px 4px 18px 0 rgba(0, 0, 0, 0.5);

  @media (max-width: 1023px) {
    margin: 0 auto;
    width: 90%;
  }

  div {
    padding: 42px 32px;

    p {
      text-align: left;
      font-size: 16px;

      @media (max-width: 700px) {
        font-size: 16px;
      }
    }

    @media (max-width: 1023px) {
      margin: 0 auto;
      padding: 28px 0;
      width: 90%;
    }
  }

  h1 {
    color: ${COLORS.BLACK};
    font-weight: ${FONT_WEIGHT.BOLD};
    margin-top: ${({ title }) => (title ? "24px" : "0")};
    font-size: 40px;
    text-align: ${({ title }) => (title ? "left" : "center")};

    @media (max-width: 1420px) {
      font-size: 34px;
    }

    @media (max-width: 700px) {
      font-size: 32px;
    }
  }
`;

export const ContentInfo = styled.div`
  margin-top: 80px;

  h2 {
    color: ${COLORS.BLACK};
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 36px;
    margin-top: 38px;

    &:first-of-type {
      margin-top: 0;
    }

    @media (max-width: 1420px) {
      font-size: 34px;
    }

    @media (max-width: 700px) {
      font-size: 32px;
    }
  }

  p {
    font-family: ${FONT_FAMILY.INTER};
    color: ${COLORS.BLACK};
    margin-top: 18px;
    line-height: 24px;

    li {
      padding: 4px;
      font-family: ${FONT_FAMILY.INTER};

      &:first-of-type {
        padding-top: 0;
      }
    }
  }
`;
