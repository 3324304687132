import styled from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

export const TitleBox = styled.div`
  width: 50%;
  text-align: center;
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 64px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  &.reverse {
    flex-direction: row-reverse;

    @media (max-width: 1023px) {
      flex-direction: column;
      margin-top: 48px;
    }
  }

  img {
    width: 648px;
    height: 480px;

    @media (max-width: 1400px) {
      width: 556px;
      height: 412px;
    }

    @media (max-width: 1200px) {
      width: 50%;
      height: calc(90vw * 0.4);
    }

    @media (max-width: 1023px) {
      width: 100%;
      height: auto;
      margin-top: 24px;
    }
  }

  div {
    width: 40%;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  h1 {
    color: ${COLORS.BLACK};
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 36px;

    @media (max-width: 1023px) {
      font-size: 32px;
    }

    @media (max-width: 349px) {
      font-size: 28px;
    }
  }

  p {
    color: ${COLORS.BLACK};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: 24px;
    padding: 32px 0;

    @media (max-width: 1023px) {
      font-size: 20px;
    }
  }

  a {
    color: ${COLORS.BLUE};
    font-weight: ${FONT_WEIGHT.MEDIUM};
    font-size: 18px;
    padding: 8px 8px 8px 0;

    @media (max-width: 1023px) {
      font-size: 16px;
    }
  }
`;
