import styled from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

export const Bar = styled.div`
  width: 100%;
  background-color: ${COLORS.WHITE};
  box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.25);
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 28px 0;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
`;

export const ImageLogo = styled.img`
  width: 320px;

  @media (max-width: 1023px) {
    margin-top: 32px;
  }

  @media (max-width: 500px) {
    width: 98%;
  }
`;

export const FooterItem = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 32px;

    &:last-of-type {
      margin-top: 0;
    }
  }
`;

export const FooterSubTitle = styled.p`
  font-size: 18px;
  font-weight: ${FONT_WEIGHT.BOLD};
  margin-bottom: 4px;
  color: ${COLORS.BLACK};

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const FooterLink = styled.a`
  color: ${COLORS.BLACK};
  text-decoration: none;
  font-size: 18px;
  margin-top: 8px;
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    justify-content: center;
  }

  img {
    width: 22px;
    margin-right: 4px;
  }
`;

export const FlexSocial = styled.div`
  display: flex;

  img {
    margin-right: 8px;
  }

  @media (max-width: 1023px) {
    margin: 0 auto;
  }
`;
