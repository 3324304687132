import styled from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";

export const TitleText = styled.p`
  font-family: ${FONT_FAMILY.INTER};
  font-size: 16px;
  color: ${COLORS.BLACK};
  font-weight: ${FONT_WEIGHT.BOLD};
  letter-spacing: 3px;
  text-transform: uppercase;
`;
