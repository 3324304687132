import styled from "styled-components";
import Euro from "../../assets/images/euro.webp";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";

export const AboutTop = styled.div`
  width: 100%;
  height: 712px;
  background: url(${Euro});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;

  @media (max-width: 1400px) {
    height: 640px;
  }

  @media (max-width: 1200px) {
    height: 600px;
  }

  @media (max-width: 1023px) {
    background: linear-gradient(white 25%, transparent 25%), url(${Euro});
    background-size: cover;
    background-position: top;
  }

  @media (max-width: 600px) {
    height: 680px;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AboutBox = styled.div`
  width: 508px;
  padding: 32px 64px;
  background-color: #fff;
  box-shadow: -4px 4px 18px 0 rgba(0, 0, 0, 0.25);
  margin-left: 192px;

  @media (max-width: 1400px) {
    margin-left: 98px;
    width: 490px;
  }

  @media (max-width: 1200px) {
    width: 94%;
    padding: 32px 0;
    margin: 0 auto;

    h1,
    p {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h1 {
    font-size: 48px;
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${COLORS.BLACK};
    margin-top: 24px;

    @media (max-width: 1400px) {
      font-size: 42px;
    }

    @media (max-width: 600px) {
      font-size: 32px;
    }
  }
`;

export const Description = styled.p`
  &.mobile {
    display: none;
  }

  width: 512px;
  font-family: ${FONT_FAMILY.INTER};
  margin-top: 5%;

  @media (max-width: 1400px) {
    width: 460px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 32px;
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
`;

export const Interchange = styled.div`
  width: 100%;
  background-color: ${COLORS.WHITE};
`;

export const InterchangeContent = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 48px 0;

  h1,
  p {
    color: ${COLORS.BLACK};
  }

  h1 {
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 28px;
  }

  p {
    font-family: ${FONT_FAMILY.INTER};
    margin-top: 18px;
  }
`;
