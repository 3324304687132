import styled from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";

type ContentProps = {
  open: boolean;
};

export const Content = styled.div<ContentProps>`
  width: 320px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: ${({ open }) => (open ? 0 : "-800px")};
  bottom: 0;
  margin: 0 40px 40px 0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  color: ${COLORS.BLACK};
  transition: 0.3s all;
  background-color: #fff;
  z-index: 999;

  @media (max-width: 600px) {
    width: 280px;
    margin: 0 12px 24px 0;
  }

  h1 {
    font-size: 18px;
    font-weight: ${FONT_WEIGHT.BOLD};
  }

  p {
    margin-top: 4px;
    font-family: ${FONT_FAMILY.INTER};
  }
`;
