import Home from "./pages/Home";
import About from "./pages/About";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Operation from "./pages/Operation";
import Differences from "./pages/Differences";
import Mission from "./pages/Mission";
import Promotion from "./pages/Promotion";
import Inter from "./pages/Inter";
import Contact from "./pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="quem-somos" Component={About} />
        <Route path="atuacao" Component={Operation} />
        <Route path="missao-feiras-eventos" Component={Mission} />
        <Route
          path="promocao-intermediacao-de-negocios"
          Component={Promotion}
        />
        <Route
          path="internacionalizacao-de-negocios-e-pessoas"
          Component={Inter}
        />
        <Route path="por-que-a-ene" Component={Differences} />
        <Route path="contato" Component={Contact} />
        <Route path="*" Component={Home} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
