import Container from "../../components/Container";
import Content from "../../components/Content";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Title from "../../components/Title";
import {
  AboutBox,
  AboutTop,
  Description,
  Flex,
  Interchange,
  InterchangeContent,
} from "./style";

export default function About() {
  return (
    <>
      <Header selected="about" />
      <Container>
        <Content>
          <AboutTop>
            <Flex>
              <AboutBox>
                <Title text="quem somos" />
                <h1>
                  Uma equipe formada por profissionais com larga experiência de
                  mercado
                </h1>
                <Description className="mobile">
                  A ENE nasce para oferecer soluções “sob medida” para quem vê o
                  mercado Europeu como uma possibilidade de fazer negócios,
                  investir ou ampliar sua área de atuação. Estamos, fisicamente
                  em Vitória e Lisboa e contamos com uma carteira de
                  especialistas e parceiros que nos dão segurança em nossos
                  projetos.
                </Description>
              </AboutBox>
              <Description className="desktop">
                A ENE nasce para oferecer soluções “sob medida” para quem vê o
                mercado Europeu como uma possibilidade de fazer negócios,
                investir ou ampliar sua área de atuação. Estamos, fisicamente em
                Vitória e Lisboa e contamos com uma carteira de especialistas e
                parceiros que nos dão segurança em nossos projetos.
              </Description>
            </Flex>
          </AboutTop>
          <Interchange>
            <InterchangeContent>
              <h1>Intercâmbio de Negócios e Projetos</h1>
              <p>
                Portugal é a porta de entrada natural para Empresas brasileiras
                que pretendem operar no mercado europeu, as vantagens de
                Portugal vão muito além da língua, acordos bilaterais de
                comércio, facilidade nas questões burocráticas e programas de
                atração de novos negócios são bons exemplos de elementos que
                tornam a internacionalização mais fácil.
                <br />
                <br />
                Apesar de ser um mercado relativamente pequeno, algo em torno de
                dez milhões de habitantes, Portugal tem se mostrado como uma
                excelente opção para quem pretende expandir seus negócios
                destacando-se o perfil demográfico, segurança, educação,
                programas de incentivos e atração de profissionais e empresas de
                inovação e tecnologia, atração do público em busca de qualidade
                de vida.
                <br />
                <br />É neste cenário que propomos auxiliar as empresas
                Capixabas na construção de estratégias de migração para a
                Europa.
              </p>
            </InterchangeContent>
          </Interchange>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
