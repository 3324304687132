import { ReactNode } from "react";
import { Box } from "./style";

type ContainerProps = {
  children: ReactNode;
};

export default function Container({ children }: ContainerProps) {
  return <Box>{children}</Box>;
}
