import styled from "styled-components";
import { COLORS } from "../../theme";

export const Content = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0 !important;
    margin-right: 8px;

    &::after {
      content: "";
      width: 80px;
      height: 80px;
      border: 8px solid ${COLORS.BLUE};
      border-top-color: ${COLORS.WHITE};
      border-radius: 50%;
      animation: loading 1s ease infinite;
    }

    @keyframes loading {
      to {
        transform: rotate(1turn);
      }
    }
  }
`;
