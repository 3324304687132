import axios from "axios";

export const getExchange = async () => {
  const exchangeData = await getExchanges();
  const b3Price = await getIndexB3();
  const euronextLisboaPrice = await getIndexEuroNextLisboa();
  return {
    exchanges: {
      USD: exchangeData?.rates.USD,
      EUR: exchangeData?.rates.EUR,
    },
    stock: {
      b3: b3Price,
      euronextLisboa: euronextLisboaPrice,
    },
  };
};

const getExchanges = async () => {
  try {
    const { data } = await axios.get(
      "https://api.exchangerate-api.com/v4/latest/BRL"
    );
    return data;
  } catch (e) {
    console.error("Erro ao buscar dados de câmbio", e);
    return null;
  }
};

const getIndexB3 = async () => {
  try {
    const { data } = await axios.get("/api/getIndexB3");
    return data?.chart.result[0]?.meta?.regularMarketPrice;
  } catch (e) {
    console.error("Erro ao buscar dados de índice B3", e);
    return null;
  }
};

const getIndexEuroNextLisboa = async () => {
  try {
    const { data } = await axios.get("/api/getIndexEuronext");
    return data?.chart.result[0]?.meta?.regularMarketPrice;
  } catch (e) {
    console.error("Erro ao buscar dados de índice B3", e);
    return null;
  }
};
