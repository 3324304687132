import Container from "../../components/Container";
import Content from "../../components/Content";
import ContentPage from "../../components/ContentPage";
import Header from "../../components/Header";
import BackImg from "../../assets/images/handshake.webp";
import Footer from "../../components/Footer";

export default function Promotion() {
  return (
    <>
      <Header selected="operation" subItem="promotion" />
      <Container>
        <Content>
          <ContentPage
            backgroundImage={BackImg}
            cover
            title="Promoção e Intermediação de Negócios"
            text="Identificar e provocar oportunidades de negócios entre Empresas Capixabas e Portuguesas é a segunda frente de trabalho proposta pela ENE."
          >
            <h2>Oportunidades de Parcerias e Intermediação de Negócios</h2>
            <p>
              Acreditamos que existem grandes oportunidades de parceria de
              negócios, que vão muito além da simples compra e venda de produtos
              e serviços. No contexto atual, as relações comerciais são
              fundamentais para o crescimento sustentável e a inovação. Estamos
              empenhados em facilitar diversas formas de colaboração, incluindo
              a representação e distribuição de produtos, a transferência de
              tecnologia e o desenvolvimento de novos mercados.
            </p>
            <h2>Monitoramento Contínuo do Ambiente de Negócios</h2>
            <p>
              Nossa equipe está sempre atenta ao ambiente de negócios,
              realizando um monitoramento constante das tendências e
              oportunidades que surgem. Essa vigilância nos permite identificar
              e conectar empresários capixabas com potenciais parceiros em
              Portugal, garantindo que aproveitem ao máximo as oportunidades
              disponíveis.
            </p>
            <h2>Estratégias Personalizadas para Cada Negócio</h2>
            <p>
              Compreendemos que cada negócio é único. Por isso, oferecemos
              estratégias personalizadas para atender às necessidades
              específicas de nossos parceiros. Se você busca expandir sua
              atuação no mercado europeu ou encontrar novos fornecedores, nossa
              equipe está pronta para auxiliar em todas as etapas do processo,
              promovendo um intercâmbio eficiente e eficaz entre as partes
              envolvidas. <br />
              Com essas ações, estamos certos de que podemos construir um
              ecossistema de negócios sólido e colaborativo, que beneficie tanto
              os empreendedores capixabas quanto seus homólogos em Portugal.
            </p>
          </ContentPage>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
