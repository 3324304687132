import Container from "../../components/Container";
import Content from "../../components/Content";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { Flex, TitleBox } from "./style";
import Feiras from "../../assets/images/event.webp";
import Negocios from "../../assets/images/handshake.webp";
import Internacionalizacao from "../../assets/images/map.webp";

export default function Operation() {
  return (
    <>
      <Header selected="operation" />
      <Container>
        <Content>
          <TitleBox>
            <Title text="Atuação" />
          </TitleBox>
          <Flex>
            <div>
              <h1>Missão, Feiras e Eventos</h1>
              <p>
                Missões e eventos são oportunidades de conhecer e fazer
                negócios, nossa proposta de atuação se dará de três maneiras
              </p>
              <a href="missao-feiras-eventos">Saiba mais {">"}</a>
            </div>
            <img src={Feiras} alt="missão, feiras e eventos" />
          </Flex>
          <Flex className="reverse">
            <div>
              <h1>Promoção e Intermediação de Negócios</h1>
              <p>
                Identificar e provocar oportunidades de negócios entre Empresas
                Capixabas e Portuguesas é a segunda frente de trabalho proposta
                pela ENE.
              </p>
              <a href="promocao-intermediacao-de-negocios">Saiba mais {">"}</a>
            </div>
            <img src={Negocios} alt="missão, feiras e eventos" />
          </Flex>
          <Flex>
            <div>
              <h1>Internacionalização de Negócios e Pessoas</h1>
              <p>
                A viabilidade de internacionalização de negócios também é uma
                das áreas de atuação da ENE
              </p>
              <a href="internacionalizacao-de-negocios-e-pessoas">
                Saiba mais {">"}
              </a>
            </div>
            <img src={Internacionalizacao} alt="missão, feiras e eventos" />
          </Flex>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
