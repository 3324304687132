import styled from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

type MenuProps = {
  selected?: boolean;
};

type MenuOpenProps = {
  isOpen?: boolean;
};

export const Bar = styled.div`
  width: 100%;
  background-color: ${COLORS.WHITE};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
  }
`;

export const ExtraInfos = styled.div`
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid #d9d9d9;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Flex = styled.div<MenuOpenProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.flex-end {
    justify-content: end;
  }

  &.menu {
    @media (max-width: 1023px) {
      flex-direction: column;
      position: fixed;
      justify-content: flex-start;
      top: 0;
      right: ${({ isOpen }) => (!isOpen ? "-120vw" : 0)};
      margin-top: 78px;
      background-color: ${COLORS.WHITE};
      width: 100%;
      height: 100%;
      z-index: 9;
      box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.25);
      transition: 0.3s all;
    }

    @media (max-width: 700px) {
      margin-top: 56px;
    }
  }
`;

export const Badges = styled.p`
  background-color: ${COLORS.BLACK};
  color: #fff;
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 14px;
  border-radius: 16px;
  padding: 4px 16px;
  margin-left: 8px;

  &.mobile {
    display: none;

    @media (max-width: 1023px) {
      display: block;
      width: 90%;
      padding: 4px 0px;
      margin: 8px auto 0 auto;
      text-align: center;

      &.first {
        margin-top: 16px;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 8px 0;
`;

export const LogoImage = styled.img<MenuProps>`
  width: 400px;
  cursor: ${({ selected }) => (!selected ? "pointer" : "auto")};

  @media (max-width: 1023px) {
    width: 330px;
  }

  @media (max-width: 700px) {
    width: 220px;
  }
`;

export const MenuItem = styled.p<MenuProps>`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-left: 4px;
  padding: 6px;
  color: ${({ selected }) => (!selected ? COLORS.BLACK : COLORS.BLUE)};
  padding-bottom: ${({ selected }) => (!selected ? "6px" : "4px")};
  border-bottom: ${({ selected }) =>
    !selected ? "none" : `2px solid ${COLORS.BLUE}`};
  cursor: ${({ selected }) => (!selected ? "pointer" : "auto")};

  @media (max-width: 1023px) {
    width: 90%;
    justify-content: center;
    margin-left: 0;
    border-bottom: 1px solid #c9c9c9;
    padding-bottom: 6px;
    font-weight: ${({ selected }) =>
      !selected ? FONT_WEIGHT.REGULAR : FONT_WEIGHT.MEDIUM};

    &:first-of-type {
      margin-top: 16px;
    }
  }
`;

export const OperationsBox = styled.div<MenuProps>`
  @media (max-width: 1023px) {
    width: 100%;
  }

  .operationOptions {
    background-color: ${COLORS.WHITE};
    text-align: center;
    position: absolute;
    margin-left: -120px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: none;
  }

  .menuItem {
    color: ${({ selected }) => (!selected ? COLORS.BLACK : COLORS.BLUE)};
    padding-bottom: ${({ selected }) => (!selected ? "6px" : "4px")};
    border-bottom: ${({ selected }) =>
      !selected ? "none" : `2px solid ${COLORS.BLUE}`};

    @media (max-width: 1023px) {
      width: 90%;
      justify-content: center;
      border-bottom: 1px solid #c9c9c9;
      padding-bottom: 6px;
      margin: 0 auto;
    }
  }

  img {
    width: 12px;
    margin-left: 6px;
    transform: rotate(-90deg);
    transition: 0.3s all;
    filter: ${({ selected }) =>
      !selected
        ? "invert(14%) sepia(28%) saturate(589%) hue-rotate(196deg) brightness(92%) contrast(97%)"
        : "invert(10%) sepia(84%) saturate(2938%) hue-rotate(227deg) brightness(98%) contrast(106%)"};

    @media (max-width: 1023px) {
      display: none;
    }
  }

  &:hover {
    .operationOptions {
      display: block;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    img {
      transform: rotate(90deg);
    }
  }
`;

export const SubItemMenu = styled.p<MenuProps>`
  color: ${({ selected }) => (!selected ? COLORS.BLACK : COLORS.BLUE)};
  padding: 12px 8px;
  cursor: ${({ selected }) => (!selected ? "pointer" : "auto")};
  border-bottom: 2px solid #d9d9d9;
  font-weight: ${({ selected }) =>
    !selected ? FONT_WEIGHT.REGULAR : FONT_WEIGHT.SEMI_BOLD};
`;

export const MenuToggle = styled.img`
  display: none;
  width: 28px;

  @media (max-width: 1023px) {
    display: block;
  }
`;
