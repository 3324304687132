export const COLORS = {
  BLACK: "#232536",
  WHITE: "#F9F9F9",
  BLUE: "#0D2178",
};

export const FONT_WEIGHT = {
  THIN: 100,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
  BLACK: 900,
};

export const FONT_FAMILY = {
  SEN: "Sen",
  INTER: "Inter",
};
