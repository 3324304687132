import Container from "../../components/Container";
import Content from "../../components/Content";
import ContentPage from "../../components/ContentPage";
import Header from "../../components/Header";
import BackImg from "../../assets/images/event.webp";
import Footer from "../../components/Footer";

export default function Mission() {
  return (
    <>
      <Header selected="operation" subItem="mission" />
      <Container>
        <Content>
          <ContentPage
            backgroundImage={BackImg}
            cover
            title="Missão, feiras e Eventos"
            text="Missões e eventos são oportunidades de conhecer e fazer negócios, nossa proposta de atuação se dará de três maneiras"
          >
            <h2>1. Organizando Missões próprias ou em parceria</h2>
            <p>
              Nossa equipe está comprometida em organizar missões de negócios,
              tanto de forma independente quanto em colaboração com outras
              entidades. Um exemplo disso é a 1ª Missão de Negócios Capixaba,
              que está programada para o primeiro semestre de 2025. Essa missão
              tem como objetivo facilitar o intercâmbio de experiências,
              conectar empresários e abrir novas oportunidades no mercado
              português, promovendo o fortalecimento da presença capixaba em
              território europeu.
            </p>
            <h2>2. Participação em Missões de terceiros</h2>
            <p>
              Buscamos identificar e apoiar a participação de empresários
              capixabas em missões de negócios já programadas por terceiros. Ao
              fazer isso, garantimos que nossos parceiros locais tenham acesso a
              experiências valiosas, networking e potenciais colaborações. Essa
              abordagem não só amplia as oportunidades de negócios, mas também
              enriquece o conhecimento do mercado português, contribuindo para
              um intercâmbio mais eficaz.
            </p>
            <h2>3. Promoção de participação em feiras e eventos de negócios</h2>
            <p>
              Promover a participação em feiras e eventos de negócios em
              Portugal é uma das nossas principais iniciativas. Acreditamos que
              esses eventos oferecem uma plataforma essencial para que empresas
              capixabas se conectem com novos mercados, apresentem seus produtos
              e serviços e estabeleçam parcerias estratégicas. Ao facilitar essa
              participação, ajudamos a criar um ambiente propício para o
              crescimento e a internacionalização dos negócios capixabas.
            </p>
          </ContentPage>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
