import Button from "../../components/Button";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { phoneNumber } from "../../mocks/data";
import {
  ButtonBox,
  Flex,
  Hero,
  HeroInfo,
  HeroTitle,
  Option,
  Options,
} from "./style";

export default function Home() {
  return (
    <>
      <Header selected="home" />
      <Hero>
        <Container>
          <Flex className="hero">
            <HeroInfo>
              <HeroTitle>
                Especialistas em negócios atuando em Portugal e Espírito Santo
              </HeroTitle>
              <ButtonBox>
                <Button
                  width="208px"
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send?phone=${phoneNumber.replace(
                        /([^0-9])/g,
                        ""
                      )}`
                    )
                  }
                />
              </ButtonBox>
            </HeroInfo>
            <Option>
              <Options>
                <p>Missão, Feiras e Eventos</p>
                <a href="missao-feiras-eventos">Saiba mais {">"}</a>
                <div className="hr"></div>
              </Options>
              <Options>
                <p>Promoção e Intermediação de Negócios</p>
                <a href="promocao-intermediacao-de-negocios">
                  Saiba mais {">"}
                </a>
                <div className="hr"></div>
              </Options>
              <Options>
                <p>Internacionalização de Negócios e Pessoas</p>
                <a href="internacionalizacao-de-negocios-e-pessoas">
                  Saiba mais {">"}
                </a>
                <div className="hr"></div>
              </Options>
            </Option>
          </Flex>
        </Container>
      </Hero>
      <Footer />
    </>
  );
}
