import Container from "../Container";
import {
  Bar,
  Flex,
  FlexSocial,
  FooterItem,
  FooterLink,
  FooterSubTitle,
  ImageLogo,
} from "./style";
import Logo from "../../assets/images/logo.svg";
import Whatsapp from "../../assets/images/whatsapp.svg";
import Email from "../../assets/images/email.svg";
import Instagram from "../../assets/images/instagram.svg";
import Linkedin from "../../assets/images/linkedin.svg";
import { email, phoneNumber } from "../../mocks/data";

export default function Footer() {
  return (
    <Bar>
      <Container>
        <Flex>
          <ImageLogo src={Logo} alt="n negócios logo" />
          <FooterItem>
            <FooterSubTitle>Páginas</FooterSubTitle>
            <FooterLink href="quem-somos">Quem somos</FooterLink>
            <FooterLink href="atuacao">Atuação</FooterLink>
            <FooterLink href="por-que-a-ene">Por que a Ene?</FooterLink>
            <FooterLink href="contato">Contato</FooterLink>
          </FooterItem>
          <FooterItem>
            <FooterSubTitle>Contato</FooterSubTitle>
            <FooterLink
              href={`https://api.whatsapp.com/send?phone=${phoneNumber.replace(
                /([^0-9])/g,
                ""
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={Whatsapp} alt="whatsapp icon" />
              {phoneNumber}
            </FooterLink>
            <FooterLink
              href={`mailto:${email}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={Email} alt="email icon" />
              {email}
            </FooterLink>
          </FooterItem>
          <FooterItem>
            <FooterSubTitle>Redes sociais</FooterSubTitle>
            <FlexSocial>
              <FooterLink
                href="https://www.instagram.com/ene_negocios/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Instagram} alt="instagram icon" />
              </FooterLink>
              <FooterLink
                href="https://www.linkedin.com/company/ene-negócios"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Linkedin} alt="linkedin icon" />
              </FooterLink>
            </FlexSocial>
          </FooterItem>
        </Flex>
      </Container>
    </Bar>
  );
}
