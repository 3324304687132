import { Content } from "./style";

export type ToastProps = {
  title: string;
  desc: string;
  open: boolean;
};

export function Toast({ title, desc, open }: ToastProps) {
  return (
    <Content open={open}>
      <h1>{title}</h1>
      <p>{desc}</p>
    </Content>
  );
}
