import { ReactNode } from "react";
import { PageContent } from "./style";

type ContentProps = {
  children: ReactNode;
};

export default function Content({ children }: ContentProps) {
  return <PageContent>{children}</PageContent>;
}
