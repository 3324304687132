import styled from "styled-components";

export const PageContent = styled.div`
  padding: 48px 0;

  @media (max-width: 1023px) {
    padding-top: 128px;
  }

  @media (max-width: 700px) {
    padding-top: 104px;
  }
`;
