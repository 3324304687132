import { useContext, useState } from "react";
import Container from "../Container";
import {
  Badges,
  Bar,
  Content,
  ExtraInfos,
  Flex,
  LogoImage,
  MenuItem,
  MenuToggle,
  OperationsBox,
  SubItemMenu,
} from "./style";
import Logo from "../../assets/images/logo-vertical.svg";
import Arrow from "../../assets/images/arrow.svg";
import Menu from "../../assets/images/menu.svg";
import Close from "../../assets/images/close.svg";
import { useExtraData } from "../../context";

type HeaderProps = {
  selected: "home" | "about" | "operation" | "contact" | "differences";
  subItem?: "mission" | "promotion" | "inter";
};

export default function Header({ selected, subItem }: HeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { exchange, stock } = useExtraData(); // Obtendo as cotações e os índices

  const horarioBrasilia = new Date().toLocaleString("pt-BR", {
    timeZone: "America/Sao_Paulo",
    hour: "2-digit",
    minute: "2-digit",
  });
  const horarioLisboa = new Date().toLocaleString("pt-PT", {
    timeZone: "Europe/Lisbon",
    hour: "2-digit",
    minute: "2-digit",
  });

  function handleToggleOpenMenu() {
    setIsOpen(!isOpen);
  }

  function handleNumberFormat(value?: number) {
    if (!value) return;
    return value?.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return (
    <Bar>
      <ExtraInfos>
        <Container>
          <Flex className="flex-end">
            {exchange?.dolar && exchange?.euro ? (
              <Badges>
                Cotação: ${handleNumberFormat(exchange?.dolar)} | €{" "}
                {handleNumberFormat(exchange?.euro)}
              </Badges>
            ) : null}
            {stock?.b3 && stock?.euronextLisboa ? (
              <Badges>
                B3 {handleNumberFormat(stock?.b3)} | Euronext Lisboa{" "}
                {handleNumberFormat(stock?.euronextLisboa)}
              </Badges>
            ) : null}
            <Badges>
              Horário: BR {horarioBrasilia} | PT {horarioLisboa}
            </Badges>
          </Flex>
        </Container>
      </ExtraInfos>
      <Content>
        <Container>
          <Flex>
            <LogoImage
              src={Logo}
              alt="logo n negócios"
              selected={selected === "home"}
              onClick={() => selected !== "home" && window.open("/", "_self")}
            />
            <MenuToggle
              src={!isOpen ? Menu : Close}
              alt="menu open and close"
              onClick={handleToggleOpenMenu}
            />
            <Flex className="menu" isOpen={isOpen}>
              <MenuItem
                selected={selected === "about"}
                onClick={() =>
                  selected !== "about" && window.open("quem-somos", "_self")
                }
              >
                Quem somos
              </MenuItem>
              <OperationsBox selected={selected === "operation"}>
                <MenuItem
                  className="menuItem"
                  selected={selected === "operation" && !subItem}
                  onClick={() =>
                    (selected !== "operation" || subItem) &&
                    window.open("atuacao", "_self")
                  }
                >
                  Atuação
                  <img src={Arrow} alt="arrow" />
                </MenuItem>
                <div className="operationOptions">
                  <SubItemMenu
                    selected={subItem === "mission"}
                    onClick={() =>
                      subItem !== "mission" &&
                      window.open("missao-feiras-eventos", "_self")
                    }
                  >
                    Missão, Feiras e Eventos
                  </SubItemMenu>
                  <SubItemMenu
                    selected={subItem === "promotion"}
                    onClick={() =>
                      subItem !== "promotion" &&
                      window.open("promocao-intermediacao-de-negocios", "_self")
                    }
                  >
                    Promoção e Intermediação de Negócios
                  </SubItemMenu>
                  <SubItemMenu
                    selected={subItem === "inter"}
                    onClick={() =>
                      subItem !== "inter" &&
                      window.open(
                        "internacionalizacao-de-negocios-e-pessoas",
                        "_self"
                      )
                    }
                  >
                    Internacionalização de Negócios e Pessoas
                  </SubItemMenu>
                </div>
              </OperationsBox>
              <MenuItem
                selected={selected === "differences"}
                onClick={() =>
                  selected !== "differences" &&
                  window.open("por-que-a-ene", "_self")
                }
              >
                Por que a Ene?
              </MenuItem>
              <MenuItem
                selected={selected === "contact"}
                onClick={() =>
                  selected !== "contact" && window.open("contato", "_self")
                }
              >
                Contato
              </MenuItem>
              {exchange?.dolar && exchange?.euro ? (
                <Badges className="mobile">
                  Cotação: ${handleNumberFormat(exchange?.dolar)} | €{" "}
                  {handleNumberFormat(exchange?.euro)}
                </Badges>
              ) : null}
              {stock?.b3 && stock?.euronextLisboa ? (
                <Badges className="mobile">
                  B3 {handleNumberFormat(stock?.b3)} | Euronext Lisboa{" "}
                  {handleNumberFormat(stock?.euronextLisboa)}
                </Badges>
              ) : null}
              <Badges className="mobile">
                Horário: BR {horarioBrasilia} | PT {horarioLisboa}
              </Badges>
            </Flex>
          </Flex>
        </Container>
      </Content>
    </Bar>
  );
}
